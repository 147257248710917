<template>
  <div class="kft-product-showcase">
    <ErrorBoundary>
      <!-- Begin::Title -->
      <Heading
        v-if="title"
        :h-class="titleHClass || `h${titleLevel}`"
        :level="titleLevel"
        :title="title"
        :use-h-tag="useHTag"
        class="mb-8 mb-md-4 kft-product-showcase__heading kft-heading--left"
      />
      <!-- End::Title -->
      <!--begin::loading-->
      <div v-show="loading" class="kft-product-showcase__container">
        <div class="product-showcase-cards-container">
          <content-loader
            v-for="index in 10"
            :key="`search-events__loading__event-${index}`"
            :unique-key="uid"
            :height="277"
            :speed="2"
            width="100%"
            viewBox="0 0 165 200"
            preserveAspectRatio="none"
            class="kft-product-card loading p-0 w-full block"
            primary-color="#ebebeb"
            secondary-color="#ffffff"
          >
            <rect height="120" rx="5" ry="5" width="160" x="0" y="0" />
            <rect height="12" rx="5" ry="5" width="160" x="0" y="130" />
            <rect height="12" rx="5" ry="5" width="100" x="0" y="145" />
            <rect height="12" rx="5" ry="5" width="35" x="0" y="162" />
            <rect height="12" rx="5" ry="5" width="35" x="45" y="162" />
            <rect height="12" rx="5" ry="5" width="40" x="0" y="186" />
          </content-loader>
        </div>
      </div>
      <!--end::loading-->

      <div
        class="product-showcase-cards-container"
        data-test-id="search-results"
      >
        <template
          v-for="(item, i) in products"
          :key="`kft-search-product-card-${item.objectID}`"
        >
          <ProductCard
            :data-insights-object-id="item.objectID"
            :duration="
              fnGetFormattedDuration(algoliaEventGetters.fnGetDuration(item))
            "
            :duration-title="$t('product.card.duration')"
            :image="algoliaEventGetters.getPrincipalImage(item)"
            :image-loading="imageLoading"
            :next="null"
            :link="algoliaEventGetters.fnGetLink(item)"
            :product-category="algoliaEventGetters.fnGetMainCategory(item)"
            :product-type="''"
            :rating="algoliaEventGetters.getAverageRating(item, localeBCP47)"
            :regular-price="
              formatPriceAmount(
                algoliaEventGetters.getPriceAmount(item),
                locale
              )
            "
            :next-event-date-time="
              formatToRelativeDate(
                algoliaEventGetters.getNextDate(item) * 1000,
                t,
                locale.split('-')?.[0]
              )
            "
            :show-add-to-cart-button="false"
            :style="{ '--index': i }"
            :title="item.title || 'simple-event-showcase'"
            :alt="algoliaEventGetters.getPrincipalAlt(item)"
            :wishlist-icon="false"
            class="products__product-card"
            handle-click
            @click="fnHandleClickOnCard(item, i)"
          />

          <ProductCard
            v-if="i === 0 && specialGiftcardEvent"
            :data-test-id="'product-card-giftcard'"
            :duration="
              fnGetFormattedDuration(
                algoliaEventGetters.fnGetDuration(specialGiftcardEvent)
              )
            "
            :image="algoliaEventGetters.getPrincipalImage(specialGiftcardEvent)"
            :link="
              localePath(
                `/giftcard/${algoliaEventGetters.fnGetPermalink(
                  specialGiftcardEvent
                )}/`
              )
            "
            :product-category="
              algoliaEventGetters.fnGetMainCategory(specialGiftcardEvent)
            "
            :regular-price="
              algoliaEventGetters.fnGetPrice(specialGiftcardEvent)
            "
            :rating="
              algoliaEventGetters.getAverageRating(
                specialGiftcardEvent,
                localeBCP47
              )
            "
            :title="specialGiftcardEvent.title || 'No title'"
            :wishlist-icon="false"
          />
          <template v-for="(position, index) in promotionSlotIndices">
            <slot v-if="i === position" :name="`promotion${index + 1}`"></slot>
          </template>
        </template>
      </div>
      <!-- End::Product List -->
    </ErrorBoundary>
  </div>
</template>

<script lang="ts">
import { ContentLoader } from 'vue-content-loader'
import { twMerge } from 'tailwind-merge'
import { type AlgoliaEventItem } from '~/composables/types/algolia'
import { sendViewItemList } from '~/composables/useGtmEvents'

export default {
  name: 'ProductShowcase',
  components: {
    ContentLoader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    queryId: {
      type: [Array, String],
      default: '',
    },
    titleLevel: {
      type: Number,
      default: 2,
    },
    titleHClass: {
      type: String,
      default: 'text-h3 font-semibold',
    },
    tags: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
    imageLoading: {
      type: String,
      default: 'lazy',
      validator: (value) => ['', 'lazy', 'eager'].includes(value),
    },
    promotionSlotIndices: {
      type: Array,
      default: () => [2, 10],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableSendEventToAlgolia: {
      type: Boolean,
      default: false,
    },
    algoliaInsightsLabel: {
      type: String,
      default: '',
    },
    gtmItemListId: {
      type: String,
      default: null,
    },
    algoliaInsightsClient: {
      type: [Object, Function, null],
      default: () => {},
    },
    giftCardEvent: {
      type: Boolean,
      default: false,
    },
    useHTag: {
      type: Boolean,
      default: true,
    },
    specialGiftcardEvent: {
      type: Object,
      default: undefined,
    },
  },
  emits: {},
  setup(props) {
    const uid = useId()
    const router = useRouter()
    const route = useRoute()
    const { t, locale } = useI18n()
    //   const { sendViewItemList, sendSelectListItem } = useGtm();
    const fnGetFormattedDuration = (d) => useGetFormattedDuration(d)

    const shouldRunSendViewItemList = ref(false)
    const mounted = ref(false)
    const localeBCP47 = convertLocaleIntoBCP47(locale.value)

    const fnSendEventToAlgolia = (route, objectID: string, position) => {
      if (props.disableSendEventToAlgolia) {
        return
      }
      if (!window?.localStorage) {
        return
      }
      let token = window?.localStorage?.getItem(
        'konfetti-algolia-tracking-user-token'
      )
      if (!token || token === 'undefined') {
        token = uuid4()
      }

      if (
        props.algoliaInsightsClient !== null &&
        props.algoliaInsightsClient !== undefined &&
        token
      ) {
        props.algoliaInsightsClient('clickedObjectIDs', {
          userToken: token,
          index: getEventListIndexName(locale.value),
          eventName: 'Event Clicked - ' + props.algoliaInsightsLabel,
          objectIDs: [objectID],
          positions: [position],
          queryId: props.queryId,
        })
      }
    }

    const routerPushToEvent = (object: AlgoliaEventItem, queryId: string) => {
      router.push(
        algoliaEventGetters.getRouterPushToEvent(
          object,
          queryId,
          locale.value,
          route.query.layout === 'sup'
        )
      )
    }

    const fnHandleClickOnCard = (item: AlgoliaEventItem, i: number) => {
      fnSendEventToAlgolia(
        algoliaEventGetters.fnGetLink(item),
        item.objectID,
        i + 1
      )

      routerPushToEvent(item, props.queryId)
      // TODO [GTM] sendSelectListItem(props.gtmItemListId, [item], i);
    }

    onMounted(() => {
      mounted.value = true
      // This would trigger the sendViewItemList event if the products
      // were loaded on server-side but these are not supposed to be seen
      // if (shouldRunSendViewItemList.value) {
      //   sendViewItemList(props.gtmItemListId, props.products)
      // }
    })

    watch(
      () => props.products,
      (newValue) => {
        if (newValue && newValue.length > 0 && props.gtmItemListId) {
          if (process.client) {
            sendViewItemList(props.gtmItemListId, props.products)
          } else {
            shouldRunSendViewItemList.value = true
          }
        }
      },
      { immediate: true }
    )

    return {
      mounted,
      uid,
      locale,
      localeBCP47,
      t,
      fnGetFormattedDuration,
      fnSendEventToAlgolia,
      fnHandleClickOnCard,
      filteredProducts: computed(() =>
        props.products.filter(
          (item) => algoliaEventGetters.fnGetMaximumPriceAmount(item) > 0
        )
      ),
    }
  },
  methods: { twMerge },
}
</script>

<style lang="postcss">
.product-showcase-cards-container {
  @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-4;
}
</style>
